.ql-snow .ql-tooltip[data-mode=link]::before {
    content: "Vložte odkaz:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "Uložit";
    padding-right: 0px;
}

.ql-snow .ql-tooltip a.ql-remove::before {
    content: "Smazat";
    margin-left: 8px;
}

.ql-snow .ql-tooltip::before {
    content: "Navštívit URL:";
    line-height: 26px;
    margin-right: 8px;
}

.ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid #ccc;
    content: 'Upravit';
    margin-left: 16px;
    padding-right: 8px;
}

.ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: 0;
    margin-right: 0.3em;
    text-align: right;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
     padding-left: 0;
}
.ql-editor ul > li::before {
     content: '';
}
.ql-editor li::before {
    display: inline-block;
    white-space: nowrap;
    width: 5px;
}
