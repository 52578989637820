.sfpi-dialog-inner-content {
    overflow-y: auto;
    max-height: 65vh;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.chip-edit {
    margin-right: 5px;
}

.form-help-layout {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 250px;
    align-items: stretch;
}

.form-help-column {
    border-left: 1px solid #ddd;
    padding-left: 10px;
}

.form-person-name-group {
    display: grid;
    gap: 20px;
    grid-template-columns: minmax(120px, 1fr) minmax(150px, 1fr) 2fr minmax(120px, 1fr);
}

.form-person-id-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.form-person-contact-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.form-person-incomes-expenses-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.form-person-incomes-expenses-group .gov-title--gamma {
    margin-bottom: 10px;
}

.form-address-street-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 3fr 1fr;
}

.form-address-city-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 3fr;
}

.form-bank-account-input-group {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 15px 3fr 15px 1fr;
    align-items: baseline;
}

.form-bank-account-input-group span {
    font-size: 24px;
    text-align: center;
    display: block;
}

.form-child-row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 150px 200px 100px;
    align-items: center;
}

.form-object-info-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
}

.form-two-params-line {
    display: grid;
    gap: 20px;
    grid-template-columns: 250px 250px 1fr;
}

.form-loan-params-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
}

.form-contact-name-mail-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
}

.form-contact-message-input {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 1fr;
}

.form-child-application-input {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.form-company-ico-group {
    display: grid;
    gap: 20px;
    grid-template-columns: 250px 1fr;
}

.form-help-layout.gov-tabs {
    display: unset;
    gap: unset;
    grid-template-columns: unset;
    align-items: unset;
}

.form-help-layout.gov-tabs .gov-tabs__links-holder {
    display: none;
    visibility: hidden;
}

.form-help-layout.gov-tabs .gov-tabs__content-holder {
    display: grid;
    gap: 10px;
    grid-template-columns: calc(70rem - 250px) 250px;
    align-items: stretch;
}

.form-help-layout.gov-tabs .gov-tabs__content-holder.no-help {
    grid-template-columns: 1fr;
}

.form-help-layout.gov-tabs .gov-tabs__content-holder .gov-tabs__content {
    visibility: visible;
    display: block;
}

.form-layout-2-1 {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 1fr;
}

.form-layout-1-2 {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 2fr;
}

.form-layout-2-1-1 {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: baseline;
}

.form-layout-3-1 {
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 1fr;
}

.form-layout-1-1 {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.form-layout-1-1-1 {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}

.form-layout-1-1-1-1-1 {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.button-select-layout {
    display: grid;
    gap: 10px;
    grid-template-columns: 50%;
    justify-content: center;
}

@media screen and (max-width: 820px) {
    .form-person-name-group, .form-person-id-group, .form-person-contact-group, .form-person-incomes-expenses-group, .form-address-street-group, .form-address-city-group, .form-bank-account-input-group, .form-company-ico-group {
        display: block;
        gap: unset;
        grid-template-columns: unset;
    }

    .form-bank-account-input-group span {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .form-help-layout.gov-tabs .gov-tabs__links-holder {
        display: block;
        visibility: visible;
    }

    .form-help-layout.gov-tabs .gov-tabs__content-holder {
        display: block;
        gap: unset;
        grid-template-columns: unset;
        align-items: unset;
    }

    .form-help-layout.gov-tabs .gov-tabs__content-holder .gov-tabs__content:not(.is-active) {
        visibility: hidden;
        display: none;
    }
}
.sfpi-mini-accordion {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
}

.sfpi-mini-accordion .sfpi-mini-accordion__content {
    display: none;
    visibility: hidden;
}

.sfpi-mini-accordion .sfpi-mini-accordion__header.is-expanded + .sfpi-mini-accordion__content {
    display: block;
    visibility: visible;
}

.sfpi-mini-accordion .sfpi-mini-accordion__content .sfpi-mini-accordion__content-inner {
    font-size: 0.9rem;
    padding: 0.1rem 0.625rem 1rem 0.625rem;
}

.sfpi-mini-accordion .sfpi-mini-accordion__content .sfpi-mini-accordion__content-inner ul li {
    font-size: 0.9rem;
    line-height: 1.2;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;
}

.sfpi-mini-accordion .sfpi-mini-accordion__content .sfpi-mini-accordion__content-inner ul li::before {
    top: 0.4rem;
}

.sfpi-mini-accordion .sfpi-mini-accordion__content .sfpi-mini-accordion__content-inner ul {
    margin-bottom: 0;
}

.sfpi-mini-accordion .sfpi-mini-accordion__header {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    font-size: .75em;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--gov-color-blue);
    padding: 0.3em 0;
    text-align: left;
    width: 100%;
}

.sfpi-mini-accordion .sfpi-mini-accordion__header .sfpi-mini-accordion__title {
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0.5rem;
}

.sfpi-mini-accordion .sfpi-mini-accordion__header .gov-icon {
    font-size: 10px;
}

.sfpi-mini-accordion .sfpi-mini-accordion__header.is-expanded .gov-icon::before {
    display:inline-block;
}

.sfpi-mini-accordion .sfpi-mini-accordion__header.is-expanded .gov-icon::before {
    transform: rotate(-180deg);
}

.sfpi-mini-accordion ~ .sfpi-mini-accordion {
    border-top: 1px solid var(--gov-color-grey-inactive);
}
.sfpi-title-section {
    font-size: 1rem;
    border-bottom: 1px solid #aaa;
    padding-bottom: 5px;
    margin-bottom: 20px;
}
.mt-10{
    margin-top: 10px;
}
.mt-20{
    margin-top: 20px;
}
.gap-20{
    column-gap: 20px;
}
.grid-colspan-2{
    grid-column: span 2;
    display: grid;
}
.w-100{
    width: 100%;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.ml-5{
    margin-left: 5px;
}
.ml-30{
    margin-left: 30px;
}
.gov-form-control__holder{
    margin-bottom: 20px;
}
.dso .gov-form-control__holder{
    margin-bottom: 100px;
}
.gov-header__nav-holder .gov-header__link.active .gov-link::before {
    transform: scale(1, 1);
}
/* Style the label */
.select-container{
    -webkit-font-smoothing: antialiased
}
.select-container label {
    margin: 0rem 0rem 0.25rem;
    color: #686868;
    cursor: pointer;
}

/* Style the select element */
.select-container select {
    width: 100%; /* Full width */
    padding: 7px 40px 7px 12px; /* Some padding and space for arrow */
    margin: 8px 0; /* Add some space around the select */
    display: inline-block; /* Make sure it's inline-block */
    border: 1px solid #686868; /* Gray border */
    cursor: pointer;
    border-radius: 3px; /* Slightly rounded corners */
    box-sizing: border-box; /* Box sizing to include padding in width */
    -webkit-appearance: none; /* Remove default arrow on Webkit browsers */
    -moz-appearance: none; /* Remove default arrow on Mozilla browsers */
    appearance: none; /* Remove default arrow */
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.270363 6.27036C0.630847 5.90988 1.21531 5.90988 1.57579 6.27036L12 16.6946L22.4242 6.27036C22.7847 5.90988 23.3692 5.90988 23.7296 6.27036C24.0901 6.63085 24.0901 7.21531 23.7296 7.57579L12.6527 18.6527C12.2922 19.0132 11.7078 19.0132 11.3473 18.6527L0.270363 7.57579C-0.090121 7.21531 -0.090121 6.63085 0.270363 6.27036Z" fill="currentColor"></path> </svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 14px center; /* Position the arrow to the right */
    background-size: 12px; /* Size of the background image */
    font-size: 16px; /* Font size */
    color: #686868; /* Text color */
}
.select-container select:active {
    border-color: unset;
    box-shadow: 0 0 0 2px var(--gov-color-blue-focus);
    outline: 0;
}
.pagination-container {
    display: grid;
    justify-content: center;
    place-items: center;
}
.h-150{
    min-height: 200px;
}
.hm-150{
    min-height: 100px;
}
.mb-100{
    margin-bottom: 100px;
}

.mb-200{
    margin-bottom: 20%;
}

.mb-25p{
    margin-bottom: 25%;
}

.mb-30{
    margin-bottom: 30%;
}

.text-white{
    color: white;
}
.text-gray{
    color: #686868;
    font-size: 16px;
}
.sfpi-document-layout {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 280px;
    align-items: start;
}
.sfpi-project-document-layout {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    align-content: space-between;
    align-items: end;
}
.project-detail{
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}
.min-height-400{
    min-height: 400px;
}
.padding-0 *{
    padding: 0 !important;
    border: none !important;
}
.align-items-normal{
    align-items: normal;
}

.fixed {
    table-layout: fixed;
}
.padding-15{
    padding: 15px;
}
.w-110px {
    width: 110px;
}
.w-100px {
    width: 100px;
}
.w-50px {
    width: 50px;
}
.w-70px {
    width: 70px;
}
.w-120px {
    width: 120px;
}
.w-150px {
    width: 150px;
}
.w-150px {
    width: 150px;
}
.w-200px {
    width: 200px;
}
.w-250px {
    width: 250px;
}
.w-350px {
    width: 350px;
}
.active-button{
    background-color: rgb(35, 98, 162); /* Slightly darker background color */
    color: white;
    box-shadow: none;
}
.document-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1rem;
}
.document-item {
    display: flex;
    align-items: center;
    text-align: center;
}

.document-item .has-file {
    color: green;
}

.document-item .no-file {
    color: red;
}

.pointer {
    cursor: pointer;
}
.w-370px {
    width: 370px;
}
.no-bullets ul li::before {
    display: none;
    content: none;
}
.employee-card {
    display: flex;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
}

.employee-card-left {
    flex: 1;
    text-align: center;
}

.employee-photo {
    width: 100%;
    margin-top: 1.5em;
    max-width: 130px;
    border-radius: 50%;
    margin-bottom: 8px;
}

.employee-name-title {
    margin-top: 8px;
}

.employee-card-right {
    flex: 3;
    padding-left: 16px;
}


.employee-card-right p {
    margin: 4px 0;
}

.two-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.column {
    flex: 1;
}
.full-width-column {
    flex: 2 100%;
}
.span-two {
    grid-column: span 2;
}

@media screen and (max-width: 1200px) {
    .w-110px {
        width: 90px;
    }
    .w-100px {
        width: 70px;
    }
    .w-70px {
        width: 60px;
    }

    .w-120px {
        width: 110px;
    }
    .w-150px {
        width: 140px;
    }
    .w-350px {
        width: 200px;
    }
}



