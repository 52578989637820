body {
  margin: 0 auto;
  font-family: 'Roboto';
}
@import '/node_modules/@gov-design-system-ce/styles/lib/styles.css';
@import '/node_modules/@gov-design-system-ce/styles/lib/layout.css';

@import '/node_modules/@gov-design-system-ce/styles/lib/critical.css';

/* Basic support for print styles */
@import '/node_modules/@gov-design-system-ce/styles/lib/print.css';

/* Support for content styles. Correct display of spaces between elements and text. */
@import '/node_modules/@gov-design-system-ce/styles/lib/content.css';
@import '/src/components/css/styles.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'sfpiicons';
  src:   url('components/assets/fonts/icons/sfpiicons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.sfpi-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sfpiicons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 0.625rem;
  display: inline-block;
  font-size: 1.5em;
  vertical-align: middle;
  position: relative;
  top: -0.125rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sfpi-icon--logo_outline:before {
  content: "\61";
}

.sfpi-icon--logo:before {
  content: "\62";
}

.sfpi-icon--instagram:before {
  content: "\63";
}

.sfpi-icon--child:before {
  content: "\64";
}

.sfpi-icon--attachment:before {
  content: "\65";
}

.sfpi-icon--security:before {
  content: "\66";
}

.sfpi-icon--access_request:before {
  content: "\67";
}

.sfpi-icon[class*="--large"] {
  font-size: 2.5em;
}

.sfpi-icon[class*="--no_margin"] {
  margin-right: 0;
}
.footer-padding{
  padding: 2.5rem 1.25rem;
}

.chart-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 10px;
  border: 1px solid grey;
  padding: 0 0 10px 0;
  background-color: #fcfbfb;
  row-gap: 10px;
}

.chart-container > h3 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: var(--gov-color-blue);
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  padding: 5px;
}

.recharts-wrapper > svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  max-width: 95% !important;
  margin: 0 auto !important;
}

.select-dashboard {
  margin-left: 90px;
}

.row-gap {
  row-gap: 10px;
}

.padding-top-0 {
  padding-top: 0;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.red {
  background-color: #c52a3a;
  color: white !important;
  font-weight: bolder;
}

.green {
  background-color: #6fbd2c;
  color: white !important;
  font-weight: bolder;
}

.visible {
  z-index: 100;
}

.table-kpi td {
  text-align: center;
  position: relative;
}
.table-kpi tr {
    border-bottom: none !important;
}
.table-kpi td:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: -5px; /* Adjust the value as needed */
  bottom: 0;
  width: 15px; /* Adjust the value as needed */
  background-color: white; /* Change to white if needed */
}
